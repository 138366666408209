import { Box } from "@chakra-ui/layout"
import { SystemStyleObject } from "@chakra-ui/styled-system"

const PersonIcon = ({ sx }: { sx?: SystemStyleObject }): JSX.Element => (
  <Box sx={sx}>
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3333 18.25V16.4167C16.3333 15.4442 15.947 14.5116 15.2593 13.8239C14.5717 13.1363 13.6391 12.75 12.6666 12.75H5.33329C4.36083 12.75 3.4282 13.1363 2.74057 13.8239C2.05293 14.5116 1.66663 15.4442 1.66663 16.4167V18.25"
        stroke="#FAFAFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00004 9.08333C11.0251 9.08333 12.6667 7.44171 12.6667 5.41667C12.6667 3.39162 11.0251 1.75 9.00004 1.75C6.975 1.75 5.33337 3.39162 5.33337 5.41667C5.33337 7.44171 6.975 9.08333 9.00004 9.08333Z"
        stroke="#FAFAFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
)

export default PersonIcon
