import NextLink from "next/link"
import { Box, Flex, Link, Text, VisuallyHidden } from "@chakra-ui/react"
import FacebookIcon from "./Icons/Facebook"
import InstagramIcon from "./Icons/InstagramIcon"
import Logo from "./Logo"

const secondaryNav: Array<{ label: string; href: string }> = [
  {
    label: "Meistä",
    href: "/meista",
  },
  {
    label: "FAQ",
    href: "/faq",
  },
  {
    label: "Käyttöehdot",
    href: "/kayttoehdot",
  },
  {
    label: "Tilaus- ja toimitusehdot",
    href: "/tilaus-ja-toimitusehdot",
  },
  {
    label: "Evästekäytäntömme",
    href: "/evastekaytantomme",
  },
]

export const Footer = (): JSX.Element => (
  <Box
    as="footer"
    w="100%"
    maxW="125rem"
    p="4"
    mt={[6, null, 8, 12, 16]}
    mx="auto"
    sx={{
      "@media print": {
        display: "none",
      },
    }}
  >
    <Flex
      w="100%"
      pb={3}
      justifyContent="space-between"
      alignItems="baseline"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
    >
      <Box>
        <Logo sx={{ width: ["80px", "90px"] }} />
      </Box>
      <Box display="flex" flexDirection="row">
        <Link
          href="https://www.facebook.com/chefsliveofficial"
          target="_blank"
          rel="noreferrer noopener"
          px="2"
          mr="2"
          borderRadius="3xl"
          _hover={{ color: "gray.200" }}
        >
          <FacebookIcon />
          <VisuallyHidden>Facebook</VisuallyHidden>
        </Link>
        <Link
          href="https://www.instagram.com/chefslive_official/"
          target="_blank"
          rel="noreferrer noopener"
          px="2"
          borderRadius="3xl"
          _hover={{ color: "gray.200" }}
        >
          <InstagramIcon />
          <VisuallyHidden>Instagram</VisuallyHidden>
        </Link>
      </Box>
    </Flex>
    <Flex
      w="100%"
      justifyContent={["flex-start", null, "space-between"]}
      flexDirection={["column", null, "row"]}
      alignItems="center"
      pt={3}
    >
      <Box w={["100%", null, "80%"]}>
        {secondaryNav.map((item, i: number) => (
          <NextLink key={i} href={item.href} passHref>
            <Link mr={4}>{item.label}</Link>
          </NextLink>
        ))}
      </Box>
      <Text pt={[4, null, 0]} color="gray.200">
        ©{new Date().getFullYear()} Chefslive Oy
      </Text>
    </Flex>
  </Box>
)
