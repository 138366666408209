import { Document } from "@contentful/rich-text-types"
import { IBlockGridFields } from "../typings/contentful"

// Show
export type ContentImage = {
  id: string
  alt: string | null
  fileName: string
  url: string
  width: number | null
  height: number | null
}

export type Talent = {
  name: string
  bio: Document | null
  profileImage: ContentImage
}

export type TalentPage = {
  title: string
  coverImage: ContentImage
  thumbnail: ContentImage
  slug: string
  content: BlockCarousel[]
  talents: Talent[]
}

export type IngredientItem = {
  id: string
  unit: string | null
  item: string
}

export type Recipe = {
  id: string
  name: string
  instructions: Document | null
  ingredients: IngredientItem[] | null
  tips: Document | null
}

export enum Version {
  redacted,
  free,
  paid,
}

export enum eventStatus {
  future = "future",
  starting = "starting",
  live = "live",
  past = "past",
}

export type Show = {
  uuid: string
  showType: "Public" | "Private" | null
  title: string
  slug: string
  version: Version
  price: string
  showStartTime: string
  showEndTime: string
  intro: Document | null
  coverImage: ContentImage
  coverImageThumbnail: ContentImage | null
  talent: Talent[] | null
  menu?: Document | null
  recipes?: Recipe[] | null
  shoppingListFood?: Document | null
  shoppingListDrinks?: Document | null
  kitchenItemInstructions?: Document | null
  vimeoEventId?: string | null
}

export type Block = {
  id: string
  type:
    | "blockCoverImage"
    | "blockHighlightedItem"
    | "blockCarousel"
    | "blockTalentGrid"
    | "blockPromo"
    | "blockGrid"
    | "blockSteps"
    | "blockCoverImage"
}

export interface BlockHighlight extends Block {
  heading: string | null
  show: Show
}

export interface BlockCarousel extends Block {
  heading: string | null
  shows: Show[]
}

export interface BlockTalentGrid extends Block {
  talents: TalentPage[]
}

export interface BlockCoverImage extends Block {
  heading: string | null
  image: ContentImage
}

export interface BlockPromo extends Block {
  heading: string
  description: string | null
  link: {
    internal: boolean
    label: string
    url: string
  }
}

export type Step = {
  image: ContentImage | null
  text: Document | null
}
export interface BlockSteps extends Block {
  heading: string
  steps: Step[]
}

export type GridItem = {
  type: string
  item: Show | Promotion
}

export interface BlockGrid extends Block {
  layout: IBlockGridFields["layout"]
  items: GridItem[]
}

export type Promotion = {
  id: string
  heading: string
  byline: string | null
  cta: string
  url: string
  internal: boolean
  image: ContentImage
  ribbon: string | null
}

export type GroupTicket = {
  uuid: string
  title: string
  slug: string
  expiryDate: string
  show: Show
}

export type PageBlocks =
  | BlockCoverImage
  | BlockHighlight
  | BlockCarousel
  | BlockTalentGrid
  | BlockPromo
  | BlockGrid
  | BlockSteps
  | BlockCoverImage
