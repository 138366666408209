import "@fontsource/anton/400.css"
import "@fontsource/fira-code/400.css"
import "@fontsource/poppins/latin-400.css"
import "@fontsource/poppins/latin-600.css"
import "@fontsource/pt-serif/400-italic.css"
import "swiper/scss"
import { useEffect } from "react"
import NextApp, { AppProps, AppContext } from "next/app"
import { Box, ChakraProvider } from "@chakra-ui/react"
import { NextIntlProvider } from "next-intl"
import TagManager from "react-gtm-module"
import FacebookPixel from "@components/FacebookPixel"
import { Footer } from "@components/Footer"
import Header from "@components/Header"
import { UserContextProvider } from "@contexts/userContext"
import theme from "../styles/theme"

interface MyAppProps extends AppProps {
  messages: {
    [key: string]: string
  }
}

function MyApp({ Component, messages, pageProps }: MyAppProps): JSX.Element {
  useEffect(() => {
    if (
      typeof process.env.NEXT_PUBLIC_GTM_ID === "string" &&
      typeof process.env.NEXT_PUBLIC_GTM_AUTH === "string" &&
      typeof process.env.NEXT_PUBLIC_GTM_PREVIEW === "string"
    ) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
        preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
      })
    }
  }, [])

  return (
    <NextIntlProvider
      messages={messages}
      now={new Date(pageProps.now)}
      timeZone="Europe/Helsinki"
    >
      <ChakraProvider resetCSS theme={theme}>
        <UserContextProvider>
          <FacebookPixel />
          <Header />
          <Box p="4" pt="0" minHeight="75vh">
            <Component {...pageProps} />
          </Box>
          <Footer />
        </UserContextProvider>
      </ChakraProvider>
    </NextIntlProvider>
  )
}

MyApp.getInitialProps = async (ctx: AppContext) => {
  const initialProps = await NextApp.getInitialProps(ctx)
  const { locale } = ctx.router

  return {
    ...initialProps,
    messages: require(`../messages/${locale || "fi"}.json`),
  }
}

export default MyApp
