import { useEffect, useState, useCallback, useRef } from "react"
import Link from "next/link"
import { Search2Icon } from "@chakra-ui/icons"
import { Image as ChakraImage } from "@chakra-ui/react"
import {
  Box,
  CircularProgress,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Text,
  useTheme,
  Tooltip,
} from "@chakra-ui/react"
import { useTranslations } from "next-intl"

interface SearchResult {
  title: string
  link: string
  thumbnail: string | null
}

interface Theme {
  colors: {
    orange: string
    gray: { [key: number]: string }
    black: string
    white: string
  }
  fontSizes: {
    sm: string
  }
}

const SEARCH_DELAY = 500
const BLUR_DELAY = 150
const RESULT_CLICK_DELAY = 200

const SearchBar = () => {
  const t = useTranslations()
  const theme = useTheme() as Theme
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [error, setError] = useState(false)
  const timeoutId = useRef<NodeJS.Timeout | null>(null)

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false)
    }, BLUR_DELAY)
  }

  const handleSearch = useCallback(async () => {
    if (searchTerm.length < 3) {
      setSearchResults([])
      setError(false)
      return
    }

    setIsLoading(true)
    const response = await fetch(
      `/api/search/searchRecipes?keyword=${searchTerm}`
    )
    const data = await response.json()

    if (response.ok) {
      setSearchResults(data)
      setError(false)
    } else {
      console.error(data.error)
      setError(true)
      setSearchResults([])
    }
    setIsLoading(false)
  }, [searchTerm, setSearchResults, setError, setIsLoading])

  const handleResultClick = () => {
    setTimeout(() => {
      setSearchTerm("")
      setSearchResults([])
      setIsFocused(false)
    }, RESULT_CLICK_DELAY)
  }

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(handleSearch, SEARCH_DELAY)
  }, [searchTerm, handleSearch])

  return (
    <Box position="relative">
      <Tooltip
        className="desktop-tooltip"
        bg={theme.colors.gray[300]}
        borderRadius="xl"
        hasArrow
        label={String(t("search.tooltip"))}
        placement="left"
        fontSize="xs"
        sx={{
          display: { base: "none", md: "block" },
        }}
      >
        <InputGroup
          size="sm"
          width={{ base: "200px", md: "400px" }}
          onBlur={handleBlur}
        >
          <InputLeftElement pointerEvents="none">
            <Search2Icon color={theme.colors.gray[200]} />
          </InputLeftElement>
          <Input
            spellCheck="false"
            focusBorderColor={theme.colors.orange}
            borderRadius="xl"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsFocused(true)}
            placeholder={String(t("search.searchMenu"))}
          />
          {isLoading && (
            <InputRightElement>
              <CircularProgress
                isIndeterminate
                color={theme.colors.orange}
                size="24px"
              />
            </InputRightElement>
          )}
        </InputGroup>
      </Tooltip>
      {isFocused && (
        <Box
          onBlur={() => setIsFocused(false)}
          position="absolute"
          width="90%"
          maxHeight="200px"
          borderRadius="xl"
          borderTopRadius="0"
          overflowY="auto"
          top="100%"
          left="5%"
          right="5%"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 1)"
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              background: theme.colors.gray[200],
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: theme.colors.gray[300],
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: theme.colors.black,
            },
          }}
        >
          {error ? (
            <Box p={2}>
              <Text color={theme.colors.black}>
                {String(t("search.error"))}
              </Text>
            </Box>
          ) : searchResults.length === 0 ? (
            <Box bg={theme.colors.black} p={2}>
              <Text color={theme.colors.gray[200]}>
                {String(t("search.noResults"))}
              </Text>
            </Box>
          ) : (
            searchResults.map((show, index) => (
              <Box
                key={index}
                bg={theme.colors.black}
                p={2}
                boxShadow="md"
                _hover={{ bg: theme.colors.gray[300] }}
                onClick={handleResultClick}
              >
                <Link href={`/${t("common.show")}/${show.link}`}>
                  <a href="">
                    <Box display="flex" alignItems="center">
                      {show.thumbnail && (
                        <ChakraImage
                          src={show.thumbnail}
                          alt={show.title}
                          boxSize="50px"
                          objectFit="cover"
                          borderRadius="full"
                          marginRight={2}
                          sx={{
                            display: { base: "none", md: "block" },
                          }}
                        />
                      )}
                      <Text
                        color={theme.colors.white}
                        fontSize={theme.fontSizes.sm}
                      >
                        {show.title}
                      </Text>
                    </Box>
                  </a>
                </Link>
              </Box>
            ))
          )}
        </Box>
      )}
    </Box>
  )
}

export default SearchBar
