import {
  Box,
  Link,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useTranslations } from "use-intl"
import supabase from "@utils/initSupabase"

type ResetCompleteProps = {
  resetComplete: () => void
  token: string
}

const ResetPassword = (props: ResetCompleteProps): JSX.Element => {
  const { resetComplete, token } = props
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors: formErrors, isSubmitting },
  } = useForm()
  const toast = useToast()
  const t = useTranslations("resetPasswordModal")

  const submitNewPassword = async ({ password }: { password?: string }) => {
    const { error, user } = await supabase.auth.api.updateUser(token, {
      password,
    })

    if (user) {
      toast({
        title: t("toastSuccessTitle"),
        description: t("toastSuccessMsg"),
        status: "success",
        duration: 5000,
        isClosable: true,
      })
      resetComplete()
    }

    if (error) {
      toast({
        title: t("toastErrorTitle"),
        description: t("toastErrorMsg"),
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
  }

  return (
    <Modal
      isOpen={true}
      isCentered
      onClose={() => {
        return // required by Modal
      }}
    >
      <ModalOverlay />
      <ModalContent background="transparent">
        <ModalHeader>{t("resetModalTitle")}</ModalHeader>
        <ModalBody>
          <Text mt="4">{t("resetModalMsg")}</Text>
          <form onSubmit={handleSubmit(submitNewPassword)}>
            <FormControl isInvalid={formErrors.password}>
              <FormLabel htmlFor="password" mt="4">
                {t("formPasswordLabel")}
                <Box display="inline" color="red">
                  *
                </Box>
              </FormLabel>
              <Input
                id="password"
                type="password"
                required
                placeholder={String(t("formPasswordPlaceholder"))}
                focusBorderColor="orange"
                {...register("password", {
                  required: String(t("formPasswordRequired")),
                  minLength: {
                    value: 6,
                    message: String(t("formPasswordError")),
                  },
                })}
              />
              <FormErrorMessage color="white">
                {formErrors.password && formErrors.password.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formErrors.password_repeat}>
              <FormLabel htmlFor="password_repeat" mt="4">
                {t("formPasswordRepeatLabel")}
                <Box display="inline" color="red">
                  *
                </Box>
              </FormLabel>
              <Input
                id="password_repeat"
                type="password"
                required
                placeholder={String(t("formPasswordRepeatPlaceholder"))}
                focusBorderColor="orange"
                {...register("password_repeat", {
                  required: String(t("formPasswordRequired")),
                  validate: (value) =>
                    value === watch("password") ||
                    String(t("formRepeatPasswordError")),
                })}
              />
              <FormErrorMessage color="white">
                {formErrors.password_repeat &&
                  formErrors.password_repeat.message}
              </FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              size="md"
              w="100%"
              mt="8"
              disabled={isSubmitting}
            >
              {isSubmitting
                ? t("formPasswordSubmitting")
                : t("formPasswordSubmit")}
            </Button>
          </form>
          <Link
            display="block"
            textAlign="center"
            mt="6"
            fontSize="sm"
            onClick={resetComplete}
          >
            {t("skipReset")}
          </Link>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ResetPassword
