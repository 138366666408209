import Link from "next/link"
import { Button, List, ListItem, Text } from "@chakra-ui/react"
import { useTranslations } from "use-intl"
import { useUser } from "@contexts/userContext"
import AccountMenu from "./AccountMenu"
import SearchBar from "./ContentfulSearchBar"

const MenuItem = ({ href, label }: { href: string; label: string }) => (
  <ListItem>
    <Link href={href} passHref>
      <Text variant="navLink" as="a">
        {label}
      </Text>
    </Link>
  </ListItem>
)

const MenuDesktop = (): JSX.Element => {
  const { user } = useUser()
  const t = useTranslations("navigation")

  return (
    <List
      display="flex"
      flexDirection="row"
      alignItems="center"
      zIndex="600"
      sx={{ li: { ml: "5" } }}
    >
      <ListItem>
        <SearchBar />
      </ListItem>
      <MenuItem href="/" label="Etusivulle" />
      <MenuItem
        href={String(t("giftCardsUrl"))}
        label={String(t("giftCardsLabel"))}
      />
      <MenuItem
        href={String(t("companiesGroupsUrl"))}
        label={String(t("companiesGroupsLabel"))}
      />
      <MenuItem href={String(t("faqUrl"))} label={String(t("faqLabel"))} />

      {!user ? (
        <>
          <MenuItem
            href={String(t("signInUrl"))}
            label={String(t("signInLabel"))}
          />
          <ListItem>
            <Link href={String(t("signUpUrl"))} passHref>
              <Button as="a">{t("signUpLabel")}</Button>
            </Link>
          </ListItem>
        </>
      ) : (
        <ListItem>
          <AccountMenu />
        </ListItem>
      )}
    </List>
  )
}

export default MenuDesktop
