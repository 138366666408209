import { eventStatus, Show, Step, Talent } from "@typings/index"
import {
  add,
  sub,
  format,
  isFuture,
  parseISO,
  isWithinInterval,
} from "date-fns"
import * as R from "ramda"
import { PriceData } from "./pricing"

const checkAccessWithIDs = (
  accessList: string[] | null,
  showID: string
): boolean => {
  if (R.isNil(accessList)) return false

  const access = accessList.find((accessID: string) => accessID === showID)

  if (R.isNil(access)) {
    return false
  }

  return true
}

const isSameDay = (start: Date, end: Date): boolean =>
  format(start, "dd.MM.yyyy") === format(end, "dd.MM.yyyy")

const getEventStatus = (
  startDate: Date | string,
  endDate: Date | string
): eventStatus => {
  const start = startDate instanceof Date ? startDate : parseISO(startDate)
  const earlyStart = sub(start, { minutes: 15 })
  const end = endDate instanceof Date ? endDate : parseISO(endDate)
  const extendedEnd = add(end, { minutes: 30 })
  const now = new Date()

  // Is the current time withing a range of 15min before the start
  if (
    isWithinInterval(now, {
      start: earlyStart,
      end: start,
    })
  ) {
    return eventStatus.starting
  }

  if (isFuture(start)) {
    return eventStatus.future
  }

  // Start date is no in the future, but end is -> We are live
  if (
    isWithinInterval(now, {
      start: start,
      end: extendedEnd,
    })
  ) {
    return eventStatus.live
  }

  // Air date is in the past
  return eventStatus.past
}

const siteUrl =
  process.env.NEXT_PUBLIC_SITE_URL ||
  process.env.VERCEL_URL ||
  "http://localhost:3000" // eslint-disable-line

const hasTagManager =
  typeof process.env.NEXT_PUBLIC_GTM_ID === "string" &&
  typeof process.env.NEXT_PUBLIC_GTM_AUTH === "string" &&
  typeof process.env.NEXT_PUBLIC_GTM_PREVIEW === "string"

// Capitalises the first letter of a sting and returns it
const capitalizeFirstLetter = (string: string): string => {
  return string[0].toUpperCase() + string.slice(1)
}

function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMemberUpdateSuccess = (obj: any): obj is MemberUpdateSuccess => {
  return (
    typeof obj.id === "string" &&
    typeof obj.email_address === "string" &&
    typeof obj.unique_email_id === "string" &&
    typeof obj.contact_id === "string" &&
    typeof obj.full_name === "string" &&
    typeof obj.status === "string" &&
    typeof obj.web_id === "number"
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMailchimpSegmentList = (obj: any): obj is MailchimpSegmentsList => {
  return (
    Array.isArray(obj.segments) &&
    typeof obj.list_id === "string" &&
    typeof obj.total_items === "number"
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMailchimpSegment = (obj: any): obj is Segment => {
  return (
    typeof obj.id === "number" &&
    typeof obj.name === "string" &&
    typeof obj.member_count === "number" &&
    (obj.type === "saved" || obj.type === "static" || obj.type === "fuzzy") &&
    typeof obj.created_at === "string" &&
    typeof obj.updated_at === "string"
  )
}

const isMembersAddedorRemovedResponse = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any
): obj is MembersAddedorRemovedResponse => {
  return (
    "members_added" in obj &&
    "members_removed" in obj &&
    typeof obj.total_added === "number" &&
    typeof obj.error_count === "number"
  )
}

const isShow = (show: any): show is Show => {
  const properties = [
    "uuid",
    "title",
    "slug",
    "version",
    "showStartTime",
    "showEndTime",
  ]

  return !R.isNil(show) && properties.every((prop) => prop in show)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isPriceData = (data: any): data is PriceData => {
  const properties = [
    "pretaxPrice",
    "tax",
    "price",
    "currencyCode",
    "currencySymbol",
    "discount",
  ]

  return !R.isNil(data) && properties.every((prop) => prop in data)
}

const isStep = (obj: any = {}): obj is Step => "text" in obj || "image" in obj

const isTalent = (obj: any): obj is Talent => {
  const properties = ["name", "bio", "profileImage", "slug"]

  return !R.isNil(obj) && properties.every((prop) => prop in obj)
}

export {
  checkAccessWithIDs,
  isSameDay,
  getEventStatus,
  siteUrl,
  hasTagManager,
  capitalizeFirstLetter,
  notEmpty,
  isMemberUpdateSuccess,
  isMailchimpSegmentList,
  isMailchimpSegment,
  isMembersAddedorRemovedResponse,
  isShow,
  isPriceData,
  isStep,
  isTalent,
}
