import Link from "next/link"
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { useTranslations } from "use-intl"
import { useUser } from "@contexts/userContext"
import PersonIcon from "./Icons/Person"

const menuButtonStyles = {
  fontWeight: "semibold",
  cursor: "pointer",
  svg: {
    path: {
      transition: "stroke 0.25s ease-out",
    },
  },
  _hover: {
    color: "orange",
    transition: "color 0.25 ease-out",
    svg: {
      path: {
        stroke: "orange",
        transition: "stroke 0.25s ease-out",
      },
    },
  },
  _focus: {
    color: "orange",
    svg: {
      path: {
        stroke: "orange",
      },
    },
  },
  "> span": {
    display: "flex",
  },
}

const menuItemStyle = {
  fontWeight: "semibold",
  fontSize: "14",
  textTransform: "uppercase",
  _focus: {
    color: "orange",
  },
}

const AccountMenu = (): JSX.Element => {
  const { signOut } = useUser()
  const t = useTranslations("navigation")

  return (
    <Menu>
      <MenuButton sx={menuButtonStyles}>
        {/* {t("menuLabel")} */}
        Tili
        <PersonIcon sx={{ ml: "2" }} />
      </MenuButton>
      <MenuList bg="black" border="gray.100" borderRadius="lg">
        <Link href={String(t("accountUrl"))} passHref>
          <MenuItem as="a" sx={menuItemStyle}>
            {t("accountLabel")}
          </MenuItem>
        </Link>

        <MenuItem
          sx={menuItemStyle}
          onClick={() => {
            signOut()
          }}
        >
          {t("signOutLabel")}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default AccountMenu
