import { extendTheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"

// Colors
const colors = {
  black: "#1e1e23",
  white: "#fefefe",
  orange: "#ff443f",
  gray: {
    300: "#4E4E58",
    200: "#9999AB",
    100: "rgba(147, 147, 161, 0.2)",
  },
  green: {
    100: "#0CFF50",
  },
}

// Typography
const fonts = {
  heading: `Anton, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif`,
  subheading: `Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif`,
  byline: `PT Serif, Times New Roman, serif`,
  body: `Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif`,
  giftcard: `Fira Code, Anton, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif`,
}

const fontSizes = {
  xs: "0.75rem", // 12px
  sm: "0.875rem", // 14px
  md: "1rem", // 16px
  lg: "1.125rem", // 18px
  xl: "1.25rem", // 20px
  "1xl": "1.5rem", // 24px
  "2xl": "1.625rem", // 26px
  "3xl": "1.75rem", // 28px
  "4xl": "2.25rem", // 36px
  "5xl": "2.5rem", // 40px
  "6lx": "2.75rem", // 44px
}

const fontWeights = {
  normal: 400,
  semibold: 600,
}

const lineHeights = {
  none: 1,
  shorter: 1.1,
  short: 1.2,
  medium: 1.5,
}

// Default spacing
/* 
const spacing = {
  px: "1px",
  0.5: "0.125rem",
  1: "0.25rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.625rem",
  3: "0.75rem",
  3.5: "0.875rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  7: "1.75rem",
  8: "2rem",
  9: "2.25rem",
  10: "2.5rem",
  12: "3rem",
  14: "3.5rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  28: "7rem",
  32: "8rem",
  36: "9rem",
  40: "10rem",
  44: "11rem",
  48: "12rem",
  52: "13rem",
  56: "14rem",
  60: "15rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  96: "24rem",
}
*/

// Breakpoints
const breakpoints = createBreakpoints({
  sm: "40rem", // 640px
  md: "52rem", // 832px
  lg: "64rem", // 1024px
  xl: "75rem", // 1200px
  "2xl": "87rem", // 1392px
})

// Global styles
const global = {
  body: {
    bg: "black",
    color: "white",
    fontSize: "100%",
    a: {
      color: "orange",
      textDecoration: "none",
      transition: "color 0.25s ease-out",
      _hover: { transition: "color 0.25s ease-out" },
    },
    button: {
      transition: "color 0.25s ease-out, background 0.25s ease-out",
      _hover: {
        transition: "color 0.25s ease-out, background 0.25s ease-out",
      },
    },
    "@media print": {
      color: "#000000",
      background: "#ffffff",
    },
  },
}

// Text styles
// Primarily use these when creating anything except default text
const headingStyles = {
  heroHeading: {
    fontFamily: "heading",
    textTransform: "uppercase",
    fontSize: ["3xl", null, "5xl", null, null, "6xl"],
    lineHeight: "short",
  },
  contentHeading1: {
    fontFamily: "heading",
    textTransform: "uppercase",
    fontSize: ["1xl", null, "4xl", null, "5xl"],
    lineHeight: "short",
    fontWeight: "normal",
  },
  contentHeading2: {
    fontFamily: "heading",
    textTransform: "uppercase",
    fontSize: ["lg", null, "2xl"],
    lineHeight: "short",
  },
  giftCardCode: {
    fontFamily: "giftcard",
    textTransform: "uppercase",
    fontSize: ["lg", null, "2xl"],
    lineHeight: "short",
  },
  subHeading1: {
    fontFamily: "body",
    textTransform: "uppercase",
    fontWeight: "semibold",
    fontSize: ["sm", null, "xl"],
    lineHeight: "short",
  },
  subHeading2: {
    fontFamily: "body",
    fontWeight: "semibold",
    textTransform: "uppercase",
    fontSize: ["xs", null, "md"],
    lineHeight: "shorter",
  },
  subHeading3: {
    fontFamily: "body",
    fontWeight: "semibold",
    textTransform: "uppercase",
    fontSize: ["sm", null, "md"],
    lineHeight: "none",
  },
  subHeading4: {
    fontFamily: "body",
    fontWeight: "semibold",
    fontSize: ["sm", null, "md"],
    lineHeight: "none",
  },
  infoHeading: {
    fontFamily: "body",
    fontWeight: "semibold",
    fontSize: ["md", null, "md"],
    lineHeight: "none",
  },
}

const copyStyles = {
  byline: {
    fontFamily: "byline",
    fontWeight: "normal",
    fontSize: ["md", null, "lg"],
    lineHeight: "none",
  },
  navLink: {
    fontFamily: "body",
    fontWeight: "semibold",
    fontSize: "md",
    color: "white",
    cursor: "pointer",
    _hover: { color: "orange" },
  },
}

// Component base styles
const components = {
  Heading: {
    baseStyle: {
      fontWeight: "normal",
      textTransform: "uppercase",
      lineHeight: "shorter",
    },
    variants: {
      ...headingStyles,
    },
  },
  Text: {
    baseStyle: {
      fontFamily: "body",
      fontSize: ["sm", null, "md"],
      lineHeight: "medium",
    },
    variants: {
      ...copyStyles,
      ...headingStyles,
    },
  },
  Link: {
    baseStyle: {
      fontFamily: "body",
      fontSize: ["sm", null, "md"],
      lineHeight: "medium",
      color: "orange",
    },
    variants: {
      ...headingStyles,
      ...copyStyles,
    },
  },
  Button: {
    baseStyle: {
      borderRadius: "full",
      color: "white",
      textTransform: "uppercase",
      cursor: "pointer",
      _focus: {
        outline: "none",
      },
    },
    variants: {
      primary: {
        backgroundColor: "orange",
        _hover: {
          backgroundColor: "white",
          color: "orange",
          textDecoration: "none",
        },
      },
      secondary: {
        backgroundColor: "gray.100",
        _hover: {
          backgroundColor: "orange",
          textDecoration: "none",
        },
      },
      plain: {
        background: "none",
      },
    },
    defaultProps: {
      size: "md",
      variant: "primary",
    },
  },
  Modal: {
    baseStyle: {
      header: {
        background: "black",
        borderTopRadius: "lg",
      },
      body: {
        background: "black",
        paddingBottom: 8,
        borderBottomRadius: "lg",
      },
    },
  },
}

const shadows = {
  outline: `0 0 0 2px ${colors.gray[300]}`,
}

// The theme
const theme = extendTheme({
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  breakpoints,
  components,
  colors,
  shadows,
  styles: {
    global,
  },
  config: {
    useSystemColorMode: false,
  },
  textStyles: { ...headingStyles },
})

export default theme
