import { useEffect, useState } from "react"
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react"
import Logo from "./Logo"
import MenuDesktop from "./MenuDesktop"
import MenuMobile from "./MenuMobile"

const containerStyles = {
  width: "100%",
  px: 4,
  py: [4, null, 6],
  justifyContent: "space-between",
  alignItems: "center",
  "@media print": {
    display: "none",
  },
}

const Header = (): JSX.Element => {
  const desktop = useBreakpointValue({ base: false, md: true })
  const [showDesktopMenu, setShowDesktopMenu] = useState<boolean>(false)

  useEffect(() => {
    if (typeof desktop === "boolean") {
      setShowDesktopMenu(desktop)
    }
  }, [desktop])

  return (
    <Flex sx={containerStyles}>
      <Box>
        <Logo />
      </Box>
      {showDesktopMenu ? <MenuDesktop /> : <MenuMobile />}
    </Flex>
  )
}

export default Header
