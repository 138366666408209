import { useRef, useState } from "react"
import Link from "next/link"
import { useOutsideClick } from "@chakra-ui/hooks"
import { Button, Box, List, ListItem, Text } from "@chakra-ui/react"
import { useTranslations } from "use-intl"
import { useUser } from "@contexts/userContext"
import SearchBar from "./ContentfulSearchBar"
import MenuIcon from "./Icons/Menu"

const mobileToggleMenuStyles = {
  display: "flex",
  alignItems: "center",
  py: 2,
  _hover: { color: "orange" },
}

const mobileMenuStyles = {
  position: "fixed",
  top: 0,
  right: 0,
  width: ["80%", "50%"],
  height: "100vh",
  p: [5, 8],
  zIndex: 100,
  backgroundColor: "black",
  boxShadow: "5px 0px 30px 10px rgba(0,0,0, 0.4)",
  transition: "transform 0.2s ease-out",
}

const NavLink = ({
  href,
  label,
  onClick,
}: {
  href: string
  label: string
  onClick: () => void
}) => (
  <ListItem mb="3" fontSize="20">
    <Link href={href} passHref>
      <Text variant="navLink" as="a" onClick={onClick}>
        {label}
      </Text>
    </Link>
  </ListItem>
)

const MenuMobile = (): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false)
  const ref = useRef(null)
  const t = useTranslations("navigation")

  useOutsideClick({
    ref: ref,
    handler: () => setMenuOpen(false),
  })

  const { user, signOut } = useUser()

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const handleSignOut = () => {
    signOut()
    closeMenu()
  }

  return (
    <>
      <Box
        as="button"
        onClick={() => setMenuOpen(!menuOpen)}
        sx={mobileToggleMenuStyles}
      >
        <Text variant="navLink" as="span" mr="2">
          {t("menuLabel")}
        </Text>
        <MenuIcon />
      </Box>
      <Box
        ref={ref}
        sx={{
          ...mobileMenuStyles,
          transform: menuOpen ? `translateX(0)` : `translateX(100%)`,
        }}
      >
        <Box display="flex" justifyContent="flex-end" mb={[8, 12]}>
          <Button variant="secondary" onClick={() => setMenuOpen(false)}>
            Sulje
          </Button>
        </Box>
        <Box textAlign="center">
          <List>
            <ListItem width="100%" display="flex" justifyContent="center">
              <SearchBar />
            </ListItem>
            <NavLink
              href="/"
              label={String(t("homeLabel"))}
              onClick={closeMenu}
            />
            <NavLink
              href={String(t("giftCardsUrl"))}
              label={String(t("giftCardsLabel"))}
              onClick={closeMenu}
            />
            <NavLink
              href={String(t("companiesGroupsUrl"))}
              label={String(t("companiesGroupsLabel"))}
              onClick={closeMenu}
            />
            <NavLink
              href={String(t("faqUrl"))}
              label={String(t("faqLabel"))}
              onClick={closeMenu}
            />
            {!user ? (
              <>
                <NavLink
                  href={String(t("signInUrl"))}
                  label={String(t("signInLabel"))}
                  onClick={closeMenu}
                />
                <ListItem>
                  <Link href={String(t("signUpUrl"))} passHref>
                    <Button as="a" onClick={closeMenu}>
                      {t("signUpLabel")}
                    </Button>
                  </Link>
                </ListItem>
              </>
            ) : (
              <>
                <NavLink
                  href={String(t("accountUrl"))}
                  label={String(t("accountLabel"))}
                  onClick={closeMenu}
                />
                <ListItem mb="3" fontSize="20">
                  <Text variant="navLink" as="a" onClick={handleSignOut}>
                    {t("signOutLabel")}
                  </Text>
                </ListItem>
              </>
            )}
          </List>
        </Box>
      </Box>
    </>
  )
}

export default MenuMobile
