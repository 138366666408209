import { User, Session, AuthChangeEvent } from "@supabase/supabase-js"
import {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from "react"
import { useRouter } from "next/router"
import { useToast } from "@chakra-ui/toast"
import { definitions } from "@typings/supabase"
import { useTranslations } from "next-intl"
import ResetPassword from "@components/ResetPassword"
import supabase from "../utils/initSupabase"

type UserContextInterface = {
  user: User | null
  session: Session | null
  userDetails: definitions["users"] | null
  userLoaded: boolean
  updateUserDetails: (user: definitions["users"] | null) => void
  signOut: () => void
}

type ProviderProps = {
  children: ReactNode
}

const UserContext = createContext<UserContextInterface>({
  user: null,
  session: null,
  userDetails: null,
  userLoaded: false,
  updateUserDetails: () => {
    return
  },
  signOut: () => {
    return
  },
})

const UserContextProvider = (props: ProviderProps): JSX.Element => {
  const [userLoaded, setUserLoaded] = useState(false)
  const [session, setSession] = useState<Session | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const [userDetails, setUserDetails] = useState<definitions["users"] | null>(
    null
  )
  const [resetView, setResetView] = useState(false)
  const t = useTranslations()

  const toast = useToast()
  const router = useRouter()

  const handleResetComplete = () => {
    setResetView(false)
    // temp until PASSWORD_RECOVERY event works again
    router.push("/", undefined, { shallow: true })
  }

  const setServerCookie = async (
    event: AuthChangeEvent,
    session: Session | null
  ) => {
    const canaryCookieExists = document.cookie
      .split(";")
      .some((item) => item.trim().startsWith("canary="))

    await fetch("/api/auth", {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "same-origin",
      body: JSON.stringify({ event, session }),
    })
      .then((res) => {
        if (!res.ok) {
          console.error("Error response received from /api/auth", res.status)
        } else {
          // If the session was restored from local storage but the
          // canary cookie wasn't set we can be quite sure that the
          // server didn't get the auth cookie (sb:token) either and thus
          // couldn't authorise the user and returned the logged out data.
          // The user is very probably logged in though as we got the
          // session and thus we'll need to refetch the server data.
          if (supabase.auth.session() && !canaryCookieExists) {
            // For more context for the below trick read
            // https://www.joshwcomeau.com/nextjs/refreshing-server-side-props/
            router.replace(router.asPath)
          }
        }
      })
      .catch((e) => {
        console.error("Error when setting or deleting auth cookie", e)
      })
  }

  const authCallback = async (
    event: AuthChangeEvent,
    session: Session | null
  ) => {
    setSession(session)
    setUser(session?.user ?? null)
    await setServerCookie(event, session)

    switch (event) {
      case "SIGNED_OUT":
        toast({
          title: t("signout.signoutTitle"),
          description: t("signout.signoutMessage"),
          status: "success",
          duration: 5000,
          isClosable: true,
        })
        router.push("/")
        break
      case "SIGNED_IN":
        // Only redirect on sign in if the user is on the sign in page.
        if (router.asPath === "/kirjaudu" || router.asPath === "/signin") {
          router.push("/")
        }
        break
      case "USER_UPDATED":
        setUser(session?.user ?? null)
        break
      case "PASSWORD_RECOVERY":
      case "USER_DELETED":
      case "TOKEN_REFRESHED":
      default:
        console.info(
          `${event} event received but we don't have any special handling for this event`
        )
        break
    }
  }

  useEffect(() => {
    const sesh = supabase.auth.session()
    setSession(sesh)
    setUser(sesh?.user ?? null)

    // Check if user arrived with password recovery link
    // temp until auth event PASSWORD_RECOVERY works again
    if (window.location.href.includes("type=recovery")) {
      setResetView(true)
    }

    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", () => {
        if (sesh) {
          setServerCookie("SIGNED_IN", sesh)
        }
      })
    } else {
      if (sesh) {
        setServerCookie("SIGNED_IN", sesh)
      }
    }

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        authCallback(event, session)
      }
    )

    return () => {
      if (authListener) {
        authListener.unsubscribe()
      }
    }
  }, []) // eslint-disable-line

  const getUserDetails = () =>
    supabase.from<definitions["users"]>("users").select("*").single()

  useEffect(() => {
    if (user) {
      getUserDetails().then(({ data }) => {
        setUserDetails(data)
        setUserLoaded(true)
      })
    }
  }, [user])

  const updateUserDetails = (
    userDetails: definitions["users"] | null
  ): void => {
    if (!userDetails) return
    setUserDetails(userDetails)
  }

  const value = {
    user,
    session,
    userDetails,
    userLoaded,
    updateUserDetails,
    signOut: () => {
      setUserDetails(null)
      return supabase.auth.signOut()
    },
  }

  return (
    <UserContext.Provider value={value}>
      {props.children}
      {resetView && (
        <ResetPassword
          token={session?.access_token || ""}
          resetComplete={handleResetComplete}
        />
      )}
    </UserContext.Provider>
  )
}

const useUser = (): UserContextInterface => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`)
  }
  return context
}

export { UserContextProvider, useUser }
