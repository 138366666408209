import { useEffect } from "react"
import { useRouter } from "next/router"
import Script from "next/script"
import { siteUrl } from "@utils/helpers"

const FacebookPixel = () => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      if (typeof window !== "undefined" && "fbq" in window) {
        fbq("track", "PageView")
      }
    }

    router.events.on("routeChangeComplete", handleRouteChangeComplete)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete)
    }
  }, [router])

  return (
    <>
      {siteUrl === "https://www.chefs.live" && (
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1918315978370902');
            `,
          }}
        />
      )}
    </>
  )
}

export default FacebookPixel
